/*
	Widths
Phone:	640px,
Tablet:	768px,
Deskt:	1024px
*/
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css");
body {
  /*
  .tooltip {
  	> *, > *::before {
  		background-color: #fff !important;
  		border-top-color: #fff !important;
  		border-right-color: #fff !important;
  		border-bottom-color: #fff !important;
  		border-left-color: #fff !important;
  		color: $commentColor !important;
  	}
  	&.show {
  		opacity: .9 !important;
  	}
  }
   */
}
body .btn {
  padding: 0.2rem 0.5rem;
}
body .btn:focus, body .btn:active:focus, body .btn.active:focus, body .btn.focus, body .btn:active.focus, body .btn.active.focus {
  outline: none !important;
  box-shadow: none;
}
body .btn.btn-icon, body .btn-icon > .btn {
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  gap: 0 0;
  padding: 0;
  height: 1.8rem;
  line-height: 1.8rem;
}
body .btn.btn-icon > i, body .btn-icon > .btn > i {
  text-align: center;
  display: block;
  font-style: normal;
  padding: 0;
  width: 1.8rem;
  background-color: rgba(255, 255, 255, 0.2);
  text-shadow: 0 0 2px #000000;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 0 1px 0 0;
}
body .btn.btn-icon > i:last-child, body .btn-icon > .btn > i:last-child {
  border-width: 0 0 0 1px;
}
body .btn.btn-icon > b, body .btn-icon > .btn > b {
  display: block;
  font-style: normal;
  font-weight: normal;
  padding: 0 0.5rem;
  text-align: center;
}
body .btn.btn-icon.disabled > i, body .btn.btn-icon.disabled > b, body .btn-icon > .btn.disabled > i, body .btn-icon > .btn.disabled > b {
  background-color: rgba(153, 153, 153, 0.4);
  opacity: 0.75;
}
@media screen and (min-width: 641px) {
  body .mobile-only {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  body .mobile-no {
    display: none;
  }
}

