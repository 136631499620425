@charset "utf-8";
@import "vars";

.PageToolbar {
	.breadcrumbs {
		background-color: $background-lightGrey;
		margin: -.5rem 0 .5rem 0;
		border-bottom: 1px solid $borderColor;
		> div {
			margin: 0;
			div {
				display: inline-block;
				color: $titleColor;
				&:before {
					content: " ▹ ";
				}
				&.empty {
					font-style: italic;
				}
			}
		}
	}
	.cookie-policy {
		margin: -.5rem 0;
		padding: 0.5rem;
		background-color: $background-lightGrey;
		> div {
			box-shadow: 0 0 2px $commentColor;
			background-color: white;
			margin: .5rem auto;
			display: block;
			width: fit-content;
			padding: .5rem 1rem;
			> div {
				float: right;
			}
			h5 {
				font-weight: bold;
				padding:0;
				margin: 0;
				text-align: center;
				color: $titleColor;
			}
			p {
				padding:0;
				margin: 0;
			}
		}
	}
}