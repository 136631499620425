@charset "utf-8";
@import "vars";
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.cdnfonts.com/css/open-dyslexic');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap');

html > body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:not(.highReadability) {
		background-color: $appBackgroundColor;
		font-size: 1rem;
	}
	&.highReadability {
		background-color: $backgroundColor-highReadability;
		font-size: 1.25rem;
		--bs-body-font-size: 1.25rem;
	}
	&:not(.highReadability), .alwaysCompact {
		font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	}
	&.highReadability, .alwaysHighReadability {
		font-family: 'Open-Dyslexic', 'Lexend', sans-serif;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}
