@charset "utf-8";
@import "vars";

.Orari {
	.Sezione {
		padding: 1rem;
		> h5 {
			font-size: 110%;
			color: $titleColor;
			text-align: center;
		}
		.Elenco {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items:stretch;
			gap: .6rem;
			width: 100%;
			> div {
				min-width: 100%;
				max-width: 100%;
				@media screen and (min-width: $smThreshold ) {
					min-width: calc(33% - .2rem);
					max-width: calc(33% - .2rem);
				}
				padding: .5rem 0;
				border: .5px solid $borderColor;
				border-radius: .25rem;
				background-color: #ffffffcc;
				p {
					margin: 0 .5rem;
					padding: 0;
					label {
						color: $commentColor;
						margin-right: .5rem;
						&:after {
							content: var(--app-after-label);
						}
					}
				}
			}
		}
	}
}