@charset "utf-8";
@import "vars";

.PageHeader {
	margin-top: .5rem;
	margin-bottom: .5rem;
	position: relative;
	> [class*="container"]  {
		> .row {
			align-items: flex-end;
			> .col {
				&.logo {
					flex-grow: 0;
					img {
						width: 80px;
						@media screen and ( min-width: $desktopMinWidth ) {
							width: 180px;
						}
					}
				}
				&.title {
					flex-grow: 1;
					@media screen and ( max-width: $phoneMaxWidth ) {
						flex-basis: 50%;
					}
					text-align: left;
					font-size: 120%;
					color: $titleColor;
					@media screen and ( min-width: $desktopMinWidth ) {
						font-size: 240%;
					}
				}
				&.controls {
					min-width: fit-content;
					max-width: fit-content;
				}
			}
		}
	}
	.controlsSwitch {
		display: none;
	}
	@media screen and ( max-width: $phoneMaxWidth ) {
		.controlsSwitch {
			display: block;
			position: absolute;
			top: 0;
			right: 10px;
			font-size: 150%;
			color: $commentColor;
			cursor: pointer;
		}
		> [class*="container"] > .row > .col.controls {
			display: none;
		}
		&.showControls {
			.controlsSwitch {
				color: #fff;
				text-shadow: 0 0 8px #000;
			}
			> [class*="container"] > .row > .col.controls {
				display: block;
			}
		}
	}
}