/*
	Widths
Phone:	640px,
Tablet:	768px,
Deskt:	1024px
*/
.Orari .Sezione {
  padding: 1rem;
}
.Orari .Sezione > h5 {
  font-size: 110%;
  color: #069;
  text-align: center;
}
.Orari .Sezione .Elenco {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.6rem;
  width: 100%;
}
.Orari .Sezione .Elenco > div {
  min-width: 100%;
  max-width: 100%;
  padding: 0.5rem 0;
  border: 0.5px solid #ccc;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.8);
}
@media screen and (min-width: 576px) {
  .Orari .Sezione .Elenco > div {
    min-width: calc(33% - 0.2rem);
    max-width: calc(33% - 0.2rem);
  }
}
.Orari .Sezione .Elenco > div p {
  margin: 0 0.5rem;
  padding: 0;
}
.Orari .Sezione .Elenco > div p label {
  color: rgba(0, 0, 0, 0.4);
  margin-right: 0.5rem;
}
.Orari .Sezione .Elenco > div p label:after {
  content: var(--app-after-label);
}

