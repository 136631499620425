@charset "utf-8";
@import "vars";
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css');

body {
	.btn {
		padding: .2rem .5rem;
		&:focus,&:active:focus,&.active:focus,
		&.focus,&:active.focus,&.active.focus {
			outline: none !important;
			box-shadow: none;
		}
	}
	.btn.btn-icon, .btn-icon>.btn {
		text-align: left;
		display: flex;
		flex-flow: row nowrap;
		align-items: stretch;
		gap: 0 0;
		padding: 0;
		height: 1.8rem;
		line-height: 1.8rem;
		> i {
			text-align: center;
			display: block;
			font-style: normal;
			padding: 0;
			width: 1.8rem;
			background-color: #ffffff33;
			text-shadow: 0 0 2px #000000;
			border-style: solid;
			border-color: #00000033;
			border-width: 0 1px 0 0;
			&:last-child {
				border-width: 0 0 0 1px;
			}
		}
		> b {
			display: block;
			font-style: normal;
			font-weight: normal;
			padding: 0 .5rem;
			text-align: center;
		}
		&.disabled {
			> i, > b {
				background-color: #99999966;
				opacity: .75;
			}
		}
	}
	@include devices(mintablet) {
		.mobile-only {
			display: none;
		}
	}
	@include devices(mobile) {
		.mobile-no {
			display: none;
		}
	}
	/*
	.tooltip {
		> *, > *::before {
			background-color: #fff !important;
			border-top-color: #fff !important;
			border-right-color: #fff !important;
			border-bottom-color: #fff !important;
			border-left-color: #fff !important;
			color: $commentColor !important;
		}
		&.show {
			opacity: .9 !important;
		}
	}
	 */
}
