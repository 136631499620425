/*
	Widths
Phone:	640px,
Tablet:	768px,
Deskt:	1024px
*/
.PageHeader {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;
}
.PageHeader > [class*=container] > .row {
  align-items: flex-end;
}
.PageHeader > [class*=container] > .row > .col.logo {
  flex-grow: 0;
}
.PageHeader > [class*=container] > .row > .col.logo img {
  width: 80px;
}
@media screen and (min-width: 769px) {
  .PageHeader > [class*=container] > .row > .col.logo img {
    width: 180px;
  }
}
.PageHeader > [class*=container] > .row > .col.title {
  flex-grow: 1;
  text-align: left;
  font-size: 120%;
  color: #069;
}
@media screen and (max-width: 640px) {
  .PageHeader > [class*=container] > .row > .col.title {
    flex-basis: 50%;
  }
}
@media screen and (min-width: 769px) {
  .PageHeader > [class*=container] > .row > .col.title {
    font-size: 240%;
  }
}
.PageHeader > [class*=container] > .row > .col.controls {
  min-width: fit-content;
  max-width: fit-content;
}
.PageHeader .controlsSwitch {
  display: none;
}
@media screen and (max-width: 640px) {
  .PageHeader .controlsSwitch {
    display: block;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 150%;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
  .PageHeader > [class*=container] > .row > .col.controls {
    display: none;
  }
  .PageHeader.showControls .controlsSwitch {
    color: #fff;
    text-shadow: 0 0 8px #000;
  }
  .PageHeader.showControls > [class*=container] > .row > .col.controls {
    display: block;
  }
}

