/*
	Widths
Phone:	640px,
Tablet:	768px,
Deskt:	1024px
*/
.OfPanel {
  margin: 1rem 0 0 0;
  padding: 0;
}
.OfPanel.error {
  text-align: center;
  font-size: 120%;
  color: #900;
  font-weight: bold;
  margin: 1rem;
}
.OfPanel.error a.icon {
  display: inline-block;
  margin: -4px 2px;
  text-decoration: none;
  font-size: 125%;
  line-height: 100%;
  vertical-align: -2px;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}
.OfPanel.error a.icon:hover {
  text-shadow: 0 0 6px #000000;
}
.OfPanel.error p:not(:first-of-type) {
  color: rgba(0, 0, 0, 0.4);
}
.OfPanel.loading {
  text-align: center;
}

