/*
	Widths
Phone:	640px,
Tablet:	768px,
Deskt:	1024px
*/
.Home {
  background-color: rgba(0, 0, 0, 0.0352941176);
  max-width: 1200px;
  margin: 0 auto 25vh auto;
  padding: 1rem 0;
  border: 0.5px solid rgba(0, 0, 0, 0.4);
  border-radius: 0.25rem;
}
.Home .comment {
  color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  font-style: italic;
}
.Home h1, .Home h2, .Home h3, .Home h4 {
  color: #069;
}
.Home .mainForm .row, .Home .filtersForm .row {
  margin-bottom: 8px;
}
.Home .mainForm .row [class*=col-], .Home .filtersForm .row [class*=col-] {
  align-self: baseline;
}
.Home .mainForm .row [class*=col-].label, .Home .filtersForm .row [class*=col-].label {
  white-space: nowrap;
}
@media screen and (min-width: 641px) {
  .Home .mainForm .row [class*=col-].label, .Home .filtersForm .row [class*=col-].label {
    text-align: right;
  }
}
.Home .mainForm .row [class*=col-].label label:after, .Home .filtersForm .row [class*=col-].label label:after {
  content: var(--app-after-label);
}

