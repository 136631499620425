$commentColor: #00000066;
$borderColor: #ccc;

$backgroundColor: #eee;
$appBackgroundColor: #fff;

$titleColor: #069;
$errorColor: #900;

$backgroundColor-highReadability: #f6f6f6;
$background-lightGrey: #00000009;

$phoneMaxWidth: 640px;
$tabletMinWidth: 641px;
$tabletMaxWidth: 768px;
$desktopMinWidth: 769px;
$desktopWidth: 1024px;

$xsThreshold: 575px;
$smThreshold: 576px;
$mdThreshold: 768px;
$lgThreshold: 992px;
$xlThreshold: 1200px;

/*
	Widths
Phone:	640px,
Tablet:	768px,
Deskt:	1024px
*/

@mixin inlineIcon($icon) {
	&:before {
		content: $icon;
	}
}

@mixin grayIcon($icon) {
	filter: saturate(0) opacity(.5);
	@include inlineIcon($icon);
}

@mixin devices ($breakpoint) { //the name of the mixin is devices
	@if $breakpoint == tablet {
		@media screen and (max-width: $tabletMaxWidth) {
			@content;
		}
	}
	@if $breakpoint == mintablet {
		@media screen and (min-width: $tabletMinWidth) {
			@content;
		}
	}
	@if $breakpoint == mobile {
		@media screen and (max-width: $phoneMaxWidth) {
			@content;
		}
	}
	@if $breakpoint == desktop {
		@media (min-width: $desktopMinWidth) {
			@content;
		}
	}
}