.AddToHomeScreen {
  display: none;
}
.AddToHomeScreen.active {
  display: block;
  position: absolute;
  top: 1em;
  left: 50%;
  margin-left: -160px;
  background-color: #fff;
  box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.6);
  width: 320px;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
}
.AddToHomeScreen.active .titlebar {
  margin: 0;
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 0;
  background-color: #666;
  background-image: linear-gradient(to top, #333, #444, #555, #333);
  line-height: 10px;
  min-height: 22px;
}
.AddToHomeScreen.active .titlebar span.icon {
  order: 1;
  flex-grow: 0;
  padding: 0 4px;
  color: #ddd;
}
.AddToHomeScreen.active .titlebar span.title {
  flex-grow: 1;
  padding: 0 4px;
  order: 2;
  color: #ddd;
  font-size: 14px;
  font-weight: bold;
  text-shadow: 0 0 1px #000;
}
.AddToHomeScreen.active .titlebar span.closer {
  order: 3;
  flex-grow: 0;
  color: #ffcd39;
  padding: 0 4px;
  cursor: pointer;
}
.AddToHomeScreen.active .titlebar span.closer:hover {
  color: #ff0;
}
.AddToHomeScreen.active p {
  padding: 0;
  margin: 1em;
  text-align: start;
}
.AddToHomeScreen.active .btn-group {
  margin: 1em;
}
.AddToHomeScreen.active .btn-group button {
  font-size: 80%;
  font-weight: bold;
}

