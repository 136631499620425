@charset "utf-8";
@import "vars";

.Home {
	.comment {
		color: $commentColor;
		font-weight: bold;
		font-style: italic;
	}
	h1, h2, h3, h4{
		color: $titleColor;
	}
	background-color: $background-lightGrey;
	max-width: $xlThreshold;
	margin: 0 auto 25vh auto;
	padding: 1rem 0;
	border: .5px solid $commentColor;
	border-radius: .25rem;
	.mainForm, .filtersForm {
		.row {
			margin-bottom: 8px;
			[class*="col-"] {
				align-self: baseline;
				&.label {
					white-space: nowrap;
					@include devices(mintablet) {
						text-align: right;
					}
					label:after {
						content: var(--app-after-label);
					}
				}
			}
		}
	}
}