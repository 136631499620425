/*
	Widths
Phone:	640px,
Tablet:	768px,
Deskt:	1024px
*/
.App {
  --app-after-label: ":";
  --h5-font-size: 110%;
  --h5-font-color: #069;
  --h5-text-align: center;
}
.App *:not([class*=control]) {
  transition: all 50ms;
}
.App *:not([class*=control]):not(*) {
  transition: font 50ms;
}
.App div.hr {
  width: 100%;
  margin: 0.5rem 0;
  padding: 0;
  height: 1px;
  border: none;
  background-color: #ccc;
}

