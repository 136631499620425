/*
	Widths
Phone:	640px,
Tablet:	768px,
Deskt:	1024px
*/
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.cdnfonts.com/css/open-dyslexic");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap");
html > body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html > body:not(.highReadability) {
  background-color: #fff;
  font-size: 1rem;
}
html > body.highReadability {
  background-color: #f6f6f6;
  font-size: 1.25rem;
  --bs-body-font-size: 1.25rem;
}
html > body:not(.highReadability), html > body .alwaysCompact {
  font-family: "Titillium Web", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
html > body.highReadability, html > body .alwaysHighReadability {
  font-family: "Open-Dyslexic", "Lexend", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

