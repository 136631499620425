/*
	Widths
Phone:	640px,
Tablet:	768px,
Deskt:	1024px
*/
.FiltersPanel > h5 {
  font-size: var(--h5-font-size);
  text-align: var(--h5-text-align);
  color: var(--h5-font-color);
}

