@charset "UTF-8";
/*
	Widths
Phone:	640px,
Tablet:	768px,
Deskt:	1024px
*/
.PageToolbar .breadcrumbs {
  background-color: rgba(0, 0, 0, 0.0352941176);
  margin: -0.5rem 0 0.5rem 0;
  border-bottom: 1px solid #ccc;
}
.PageToolbar .breadcrumbs > div {
  margin: 0;
}
.PageToolbar .breadcrumbs > div div {
  display: inline-block;
  color: #069;
}
.PageToolbar .breadcrumbs > div div:before {
  content: " ▹ ";
}
.PageToolbar .breadcrumbs > div div.empty {
  font-style: italic;
}
.PageToolbar .cookie-policy {
  margin: -0.5rem 0;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.0352941176);
}
.PageToolbar .cookie-policy > div {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  background-color: white;
  margin: 0.5rem auto;
  display: block;
  width: fit-content;
  padding: 0.5rem 1rem;
}
.PageToolbar .cookie-policy > div > div {
  float: right;
}
.PageToolbar .cookie-policy > div h5 {
  font-weight: bold;
  padding: 0;
  margin: 0;
  text-align: center;
  color: #069;
}
.PageToolbar .cookie-policy > div p {
  padding: 0;
  margin: 0;
}

