/*
	Widths
Phone:	640px,
Tablet:	768px,
Deskt:	1024px
*/
.VSwitch h4 {
  font-size: 140%;
  color: #069;
  margin: 0;
  padding: 2px 0 2px 16px;
}
.VSwitch > div {
  line-height: 1.4rem;
}
.VSwitch > div .row {
  min-width: 100%;
  align-items: stretch;
  align-content: stretch;
}
.VSwitch > div .row .switchLane {
  padding: 0;
  max-width: 16px;
  min-width: 16px;
  border-style: none solid;
  border-color: #ccc;
  border-width: 1px;
  background-color: #ddd;
  position: relative;
}
.VSwitch > div .row .switchLabel {
  flex-grow: 2;
  padding: 0 0 0 8px;
}
.VSwitch > div .row .switchLabel big {
  font-size: 120%;
  vertical-align: -2px;
}
.VSwitch > div .row.first .switchLane {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-top-style: solid;
}
.VSwitch > div .row.last .switchLane {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-bottom-style: solid;
}
.VSwitch > div .row.active .switchLane > span {
  position: absolute;
  left: calc(50% - 7px);
  top: calc(50% - 7px);
  width: 14px;
  height: 14px;
  border-radius: 14px;
  border: 1px solid #ccc;
  background-color: #fff;
}
.VSwitch > div .row.active.first:not(.last) .switchLane > span {
  top: 1px;
}
.VSwitch > div .row.active.last:not(.first) .switchLane > span {
  top: unset;
  bottom: 1px;
}
.VSwitch > div .row.active .switchLabel {
  font-weight: bold;
}
.VSwitch > div .row.inactive {
  cursor: pointer;
}

