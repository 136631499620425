@charset "utf-8";
@import "vars";

.App {
	--app-after-label: ":";
	--h5-font-size: 110%;
	--h5-font-color: #{$titleColor};
	--h5-text-align: center;
	*:not([class*="control"]) {
		transition: all 50ms;
		&:not(*) {
			transition: font 50ms;
		}
	}
	div.hr {
		width: 100%;
		margin: .5rem 0;
		padding: 0;
		height: 1px;
		border: none;
		background-color: $borderColor;
	}
}
