@charset "utf-8";
@import "vars";

.OfPanel {
	margin: 1rem 0 0 0;
	padding: 0;
	&.error {
		text-align: center;
		font-size: 120%;
		color: $errorColor;
		font-weight: bold;
		margin: 1rem;
		a.icon {
			display: inline-block;
			margin: -4px 2px;
			text-decoration: none;
			font-size: 125%;
			line-height: 100%;
			vertical-align: -2px;
			text-shadow: 0 0 6px #00000066;
			&:hover {
				text-shadow: 0 0 6px #000000;
			}
		}
		p:not(:first-of-type) {
			color: $commentColor;
		}
	}
	&.loading {
		text-align: center;
	}
}